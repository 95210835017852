<template>

    <div class="home flex flex-col h-full relative">
      <div class="text-xs px-8 py-2 pt-4 bg-white">
        <router-link to="/">Home</router-link>
      </div>
      <div class="bg-white">

        <div class="flex justify-between w-full pt-0  pb-4 px-8">
          <h1 class="text-3xl leading-none">Needs Analysis</h1>
          <div class="flex -mt-2">
            <button class="button button-blue" @click="showNewNeeds"><img class="inline-block mr-2 h-3" src="../assets/icons/new-doc-icon.svg" />New Needs Analysis</button>
          </div>
        </div>


      </div>

      <div class="border-t-4 px-8 py-2 flex items-center bg-gray-50 justify-between">
        <div class="flex items-center">
          <input class="px-2 py-1 text-xs border rounded-full w-64 focus:outline-none focus:ring focus:border-blue-300 bg-white" type="text" placeholder="Search currently loaded records" v-model="searchText" />
          <button class="text-xs ml-2 button py-1 px-2 bg-white" v-if="searchText" @click="searchText = ''">Clear</button>
          <span class="ml-2 text-sm">Use column checkboxes to narrow search by column(s)</span>
        </div>

        <div class="flex items-center text-sm">
          <label class="inline-block mr-2">{{ loading?"Loading...":"Showing"}} <span>{{ docs.length }} records</span></label>

        </div>


      </div>


      <div class="border-t px-8 py-4 overflow-y-scroll pb-16">
        <table class="w-full">
          <thead>
          <tr class="text-left">
            <th class="md:w-1/4 pl-2 hover:underline cursor-pointer"><input type="checkbox" v-model="searchColumns[1]" /></th>
            <th class="md:w-1/4 hover:underline cursor-pointer" ><input type="checkbox" v-model="searchColumns[3]" /></th>
            <th class="md:w-1/4 hover:underline cursor-pointer"><input type="checkbox" v-model="searchColumns[2]" /></th>
            <th class="md:w-1/4 hover:underline cursor-pointer" ><input type="checkbox" v-model="searchColumns[7]" /></th>
            <th class="md:w-1/4 hover:underline cursor-pointer"><input type="checkbox" v-model="searchColumns[8]" /></th>
            <th class="md:w-1/4 hover:underline cursor-pointer"><input type="checkbox" v-model="searchColumns[9]" /></th>
            <th class="md:w-1/4 hover:underline cursor-pointer" ><input type="checkbox" v-model="searchColumns[10]" /></th>
            <th class="hover:underline cursor-pointer"  ><input type="checkbox" v-model="searchColumns[4]" /></th>
            <th class="hover:underline cursor-pointer" ><input type="checkbox" v-model="searchColumns[5]" /></th>
            <th class="hover:underline cursor-pointer" ><input type="checkbox" v-model="searchColumns[11]" /></th>
            <th class=""></th>

          </tr>
          <tr class="text-left" :data-direction="sortDirection">
            <th class="md:w-1/4 pl-2 hover:underline cursor-pointer" :data-active="(sortKey === 1)" @click="setSort(1)"><span class="px-2 py-1 sortable">Organization</span></th>
            <th class="md:w-1/4 hover:underline cursor-pointer" :data-active="(sortKey === 3)" @click="setSort(3)"><span class="px-2 py-1 sortable">State</span></th>
            <th class="md:w-1/4 hover:underline cursor-pointer" :data-active="(sortKey === 2)" @click="setSort(2)"><span class="px-2 py-1 sortable">City</span></th>
            <th class="md:w-1/4 hover:underline cursor-pointer" :data-active="(sortKey === 7)" @click="setSort(7)"><span class="px-2 py-1 sortable">SE</span></th>
            <th class="md:w-1/4 hover:underline cursor-pointer" :data-active="(sortKey === 8)" @click="setSort(8)"><span class="px-2 py-1 sortable">PPS</span></th>
            <th class="md:w-1/4 hover:underline cursor-pointer" :data-active="(sortKey === 9)" @click="setSort(9)"><span class="px-2 py-1 sortable">CF#</span></th>
            <th class="md:w-1/4 hover:underline cursor-pointer" :data-active="(sortKey === 10)" @click="setSort(10)"><span class="px-2 py-1 sortable">CS#</span></th>
            <th class="hover:underline cursor-pointer" :data-active="(sortKey === 4)" @click="setSort(4)"><span class="px-2 py-1 sortable">Last Updated</span></th>
            <th class="hover:underline cursor-pointer" :data-active="(sortKey === 5)" @click="setSort(5)"><span class="px-2 py-1 sortable">Last Updated By</span></th>
            <th class="hover:underline cursor-pointer" :data-active="(sortKey === 11)" @click="setSort(11)"><span class="px-2 py-1 sortable">Created By</span></th>
            <th class=""><span v-if="isAdmin" class="opacity-0 inline-block w-4"></span></th>

          </tr>
          </thead>
          <tbody>
          <tr class="hover:bg-blue-50" v-for="needs in sortedNeeds" :key="needs[6]">
            <td class="md:w-1/4 pr-2 pl-4"><router-link :to="getNeedsUrl(needs)" class="hover:underline">{{ needs[1] }}</router-link></td>
            <td class="px-2 uppercase"><span>{{ needs[3] }}</span></td>
            <td class="px-2"><span>{{ needs[2] }}</span></td>
            <td class="px-2"><span>{{ needs[7] }}</span></td>
            <td class="px-2"><span>{{ needs[8] }}</span></td>
            <td class="px-2"><span>{{ needs[9]?needs[9]:'-----' }}</span></td>
            <td class="px-2"><span>{{ needs[10]?needs[10]:'-----' }}</span></td>
            <td class="px-2"><span>{{ needs[4] | formatTS("ms") }}</span></td>
            <td class="px-2"><span>{{ needs[5] }}</span></td>
            <td class="px-2"><span>{{ needs[11] }}</span></td>
            <td class=""><img v-if="isAdmin" @click="deleteRecord(needs)" class="h-4 opacity-50 hover:opacity-100 cursor-pointer" src="../assets/icons/delete-icon.svg" /></td>
          </tr>
          </tbody>

        </table>
        <p class="pt-4 ml-4" v-if="loading">Loading records...</p>
        <p class="pt-4 ml-4" v-if="docs && !docs.length && !loading">No records found.</p>
      </div>

      <div class="fixed bottom-0 w-full border-t px-8 py-2 flex items-center bg-gray-50 justify-between text-sm">
        <div>
          <div class="bg-white rounded-full border p-1"><input class="px-2 py-1 text-sm mx-1" :placeholder="$store.state.userprofile.email" v-model="userSearch" /><button @click="updateUserRecords" class="px-2 py-1 rounded" :class="[loading?'bg-gray-200 text-black border border-gray-500':'bg-brand-green text-white border border-brand-green rounded-full']" :disabled="loading">Load User Records</button> </div>
        </div>
        <div class="flex gap-2">

          <button @click="updateWithRecentRecords" class="px-2 py-1 rounded" :class="[loading?'bg-gray-200 text-black border border-gray-500':'bg-brand-green text-white border border-brand-green']" :disabled="loading">Load Recent Updates (100)</button>
          <button @click="updateRecords" class="px-2 py-1 rounded" :class="[loading?'bg-gray-200 text-black border border-gray-500':'bg-brand-green text-white border border-brand-green']" :disabled="loading">Load Records for All Users ({{ docCount }})</button>
          <button v-if="$store.getters.hasAdminAccess" @click="exportAllRecords" class="px-2 py-1 rounded" :class="[exporting?'bg-gray-200 text-black border border-gray-500':'bg-white text-black border']" :disabled="exporting">Export All Data</button>

        </div>
      </div>

      <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="sidePanel"></div>


      <transition name="slide">
        <div class="fixed w-full h-full max-w-xl top-0 right-0 shadow-lg" v-if="sidePanel">
          <side-panel-new-needs class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll" :api="api" @close="closeSidePanel" @created="onNeedsCreated"></side-panel-new-needs>
        </div>
      </transition>

      <div v-if="exporting" class="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center">
        <div class="bg-white p-16 text-center rounded"><h2 class="text-3xl font-bold">Generating Output...</h2> <p class="mt-1">This will take a few seconds.</p></div>
      </div>


    </div>




</template>

<script>
// @ is an alias to /src


import SidePanelNewNeeds from "@/components/SidePanelNewNeeds";
import needsData from "@/plugins/needsAnalysis"

export default {
  name: 'needs-analysis',
  components: {

    SidePanelNewNeeds

  },
  data: function(){
    return {
      loadOptions: [
        "Load Most Recent",
          "Load All Records"
      ],
      loadOption: "Load Most Recent",
       sidePanel: false,
      loading: false,
      exporting: false,
      searchColumns: {
         "1":0,
         "2":0,
        "3":0,
        "4":0,
        "5":0,
        "6":0,
        "7":0,
        "8":0,
        "9":0,
        "10":0,
        "11":0, //created_by
        "12":0,

      },
      sortKey: 4,

      secondarySortKey: 0,
      sortDirection: 1,
      docs: [],
      docCount: 0,
      api: needsData,
      searchText: "",
      userSearch: ""
    }
  },
  mounted: async function(){

      let count = await this.getCount();
      this.docCount = count;
      //if(!this.$store.getters.needs.length ){
        this.loadUserOnlyData(this.$store.state.userprofile.email);
      //}


  },
  methods: {

    getLocation: function(doc){
      if(doc[3] && doc[2]){
        return `${doc[2]}, ${doc[3]}`
      }else if(doc[2]){
        return `${doc[2]}`
      }else{
        return `${doc[3]}`
      }
    },

    exportAllRecords: async function(){

      this.exporting = true;
      await this.api.exportReport(this.docs)
      this.exporting = false;

    },

    setSort(key){

      // switch(this.searchColumns[key]){
      //   case 0: {
      //     this.searchColumns[key] = -1;
      //     this.sortKey = key;
      //     this.sortDirection = -1;
      //     break;
      //   }
      //   case 1: {
      //     this.searchColumns[key] = 0;
      //     this.sortKey = Object.keys(this.searchColumns).find((colId) => { return (this.searchColumns[colId] != 0)})
      //     this.sortDirection = 0;
      //     break;
      //   }
      //   case -1: {
      //     this.searchColumns[key] = 1;
      //     this.sortKey = key
      //     this.sortDirection = 1;
      //     break;
      //   }
      // }



      if(key === this.sortKey){
        this.sortDirection = -this.sortDirection;
        return;
      }
      this.sortDirection = -1;
      this.sortKey = key;
    },

    fullname: function(profile){

      if(profile.lastname){
        if(profile.firstname){
          return (profile.firstname.slice(0,1) + " " + profile.lastname);
        }else{
          return profile.lastname
        }
      }

      return "Unknown"
    },

    deleteRecord: function(record){
      if(confirm("Delete this record permanently?")){
        this.api.delete(record[6]).then((response)=> {

          console.log(response);
          this.loadData();
        })
      }
    },

    getNeedsUrl: function(needs){
      if(needs){
        return "/needs/"+needs[6];
      }

      return "#";

    },

    getCount: async function(){
      this.loading = true;
      let count = await this.api.getCount()
          .then((response) => {
            console.log(response);
            this.loading = false;
            return response[0]
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
            alert("There was a problem loading your documents.")
          })

      return count
    },

    updateRecords: async function(){
      console.log("update")
        await this.loadData(5000)
    },

    updateWithRecentRecords: async function(){
      console.log("update")
      await this.loadData(100)
    },

    updateUserRecords: async function(){
      console.log("update")
      await this.loadUserOnlyData(this.userSearch ? this.userSearch.toLowerCase() : this.$store.state.userprofile.email)
    },


    loadUserOnlyData: async function(email, size=200){
      this.loading = true;
      return this.api.getAllForUser(email, size)
          .then((response) => {
            //console.log(response);
            this.loading = false;
            this.docs = response;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
            alert("There was a problem loading your documents.")
          })
    },

    loadData: async function(size=200){
      this.loading = true;
      return this.api.getAll(size)
      .then((response) => {
        //console.log(response);
        this.loading = false;
          this.docs = response;
      })
      .catch((error) => {
        console.error(error);
        this.loading = false;
        alert("There was a problem loading your documents.")
      })
    },

    closeSidePanel: function(){
      this.sidePanel = false;
    },

    showNewNeeds: function(){
      this.sidePanel = true;
    },

    onNeedsCreated: function(){
      this.loadData().then(() => {
        this.closeSidePanel();
      })
    }


  },

  computed: {

    isAdmin: function(){
      return this.$store.getters.isAdmin
    },

    sortedNeeds: function(){
      if(this.docs && this.docs.length){

        let searchTerms = this.searchText.toLowerCase();
        let multiword = searchTerms.includes('+');
        let searchWords = searchTerms.split("+");



        return [...this.docs].filter((doc)=>{
          if(searchTerms){

            let selectedCols = [];

            Object.keys(this.searchColumns).forEach((col) => {
              if(this.searchColumns[col]){
                selectedCols.push(col);
              }
            })

            let searchIn = "";

            if(selectedCols.length){
              selectedCols.forEach((col) => {
                searchIn += doc[col];
              })
            }else{
              searchIn = `${doc[1]}  ${doc[2]} ${doc[3]} ${doc[5]} ${doc[7]} ${doc[8]} ${doc[9]} ${doc[10]}`;
            }

            //let searchIn = `${doc[1]}  ${doc[2]} ${doc[3]} ${doc[5]} ${doc[7]} ${doc[8]} ${doc[9]} ${doc[10]}`;

            if(multiword){

              let foundAll = true;

              for( let i=0; i<searchWords.length; i++){
                foundAll = (searchIn.toLowerCase().search(searchWords[i].trim()) > -1);
                if(!foundAll){
                  break;
                }
              }
              return foundAll;

            }else{
              return (searchIn.toLowerCase().search(searchTerms) > -1)
            }




          }
          return true
        })
        .sort((a,b) => {

          let aVal = a[this.sortKey];
          let bVal = b[this.sortKey];
          if(typeof aVal === 'string'){
            aVal = aVal.toLowerCase().trim();
          }

          if(typeof bVal === 'string'){
            bVal = bVal.toLowerCase().trim();
          }

          if(!aVal || !bVal) {
            if(!aVal && bVal){
              return 1
            }

            if(aVal && !bVal){
              return -1
            }
          }

          if(aVal < bVal){
            return (this.sortDirection);
          }else if(aVal > bVal){
            return -(this.sortDirection)
          }else if(this.sortKey !== this.secondarySortKey){
            let a2val = a[this.secondarySortKey];
            let b2val = b[this.secondarySortKey];
            if(typeof a2val === 'string'){
              a2val = a2val.toLowerCase();
            }

            if(typeof b2val === 'string'){
              b2val = b2val.toLowerCase();
            }

            if(a2val < b2val){
              return 1;
            }else if(a2val > b2val){
              return -1;
            }
          }

          return 0
        })
      }
      return [];
    },

  }
}
</script>

<style>
  th[data-active = "true"] .sortable {
    @apply rounded-xl bg-blue-200 block;
  }

  th[data-active = "true"] .sortable:after {
    content: " \2193"
  }

  [data-direction = "1"] th[data-active = "true"] .sortable:after {
    content: " \2191"
  }

</style>
